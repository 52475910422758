<template>
  <div
    class="supplierSearch"
    v-watermark
  >
    <commonNavBar :title="'供应商查询'"></commonNavBar>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="value"
          show-action
          placeholder="请输入供应商名称"
          @search="onSearch"
          @input="onInput"
          autofocus
        >
          <template #action>
            <div @click="searchBtn">搜索</div>
          </template>
        </van-search>
      </form>
      <!-- 搜索历史 -->
      <div
        class="searchBar"
        v-if="historyList.length && !value.length"
      >
        <span class="label">搜索历史</span>
        <svg-icon
          icon-class="delet"
          className="myIcon"
          @click="delClick"
        />
      </div>
      <div
        class="searchList"
        v-if="historyList.length && !value.length"
      >
        <van-cell-group>
          <van-cell
            :title="item"
            v-for="(item, index) in historyList"
            :key="index"
            @click="historyClick(item)"
          />
        </van-cell-group>
      </div>
      <div
        class="suggestionList"
        v-else-if="value.length"
      >
        <van-cell-group>
          <van-cell
            v-for="(item, index) in suggestionList"
            :key="index"
            :label="`${item.city ? item.city : '--'} ${item.district ? item.district : '--'
              }`"
            center
            @click="suggestionListClick(item)"
          >
            <template #default>
              <van-tag
                type="danger"
                v-if="item.status === 1"
              >无效</van-tag>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >有效</van-tag>
            </template>
            <template #title>
              <div v-html="handlerSuggestionList(item.name)"></div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <div
        v-else
        class="emptyBox"
      >
        <van-empty
          image="search"
          description="没有任何历史搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import { deleteSearchHistory, getSearchHistory, postSearchHistory } from '@/api/system'
import { getSupplierList } from '@/api/supplier'
let timeId = null

export default {
  name: 'supplierSearch',
  data() {
    return {

      value: '',
      historyList: [],
      suggestionList: []
    }
  },
  async created() {
    const { data } = await getSearchHistory({
      searchType: 'supplierSearchByName'
    })
    this.historyList = [...data.data].slice(0, 20)
    this.$nextTick(() => {
      if (document.querySelector('input')) {
        document.querySelector('input').focus()
      }
    })
  },
  // 路由离开页面
  beforeRouteLeave(to, from, next) {
    clearTimeout(timeId)
    next()
  },
  methods: {
    // 删除历史
    delClick() {
      Dialog.confirm({
        title: '删除搜索历史',
        message: '确认清除历史记录？'
      })
        .then(() => {
          this.historyList = []
          deleteSearchHistory({
            searchType: 'supplierSearchByName'
          })
        })
        .catch(() => { })
    },
    // 输入搜索框内容
    onInput(val) {
      if (val) {
        this.suggestionList = []
        clearTimeout(timeId)
        timeId = setTimeout(async () => {
          const { data } = await getSupplierList({
            'page.current': 1,
            'page.size': 10,
            'sort.key': '0',
            'sort.value': 'desc',
            search: val,
            fuzzy: 1
          })
          this.suggestionList = [...data.data.records]
        }, 300)
      } else {
        this.suggestionList = []
      }
    },
    // 搜索
    onSearch(val) {
      if (val) {
        this.$router.push({
          path: '/workTable/supplierResult',
          query: {
            text: val
          }
        })
        postSearchHistory({
          searchType: 'supplierSearchByName',
          searchTexts: JSON.stringify([this.value])
        })
      }
    },
    // 点击搜索按钮
    searchBtn() {
      this.onSearch(this.value)
    },
    // 点击搜索建议
    suggestionListClick(item) {
      this.$router.push(`/workTable/supplierAddressInfo/${item.id}`)
      postSearchHistory({
        searchType: 'supplierSearchByName',
        searchTexts: JSON.stringify([item.name])
      })
    },
    // 点击历史
    historyClick(val) {
      this.$router.push({
        path: '/workTable/supplierResult',
        query: {
          text: val
        }
      })
      postSearchHistory({
        searchType: 'supplierSearchByName',
        searchTexts: JSON.stringify([val])
      })
    }
  },
  computed: {
    // 使用计算属性处理高亮
    handlerSuggestionList() {
      return (val) => {
        const reg = new RegExp(this.value, 'gi')
        const newItem = val.replace(reg, (match) => {
          return `<span style='color:#1990ff'>${match}</span>`
        })
        return newItem
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.supplierSearch {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 10px;
            }
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;

        .label {
          font-size: 12px;
          color: #aaaaaa;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }

      .emptyBox {
        height: calc(100% - 54px);
        position: relative;

        .van-empty {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .searchList {
      max-height: calc(100% - 54px - 24px);
      overflow: auto;
      background-color: white;

      .van-cell-group {
        .van-cell {
          min-height: 44px;
        }
      }
    }

    .suggestionList {
      max-height: calc(100% - 54px);
      overflow: auto;
      background-color: white;
    }
  }
}
</style>
